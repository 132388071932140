<template>
    <v-row justify="center">
    <v-dialog v-model="dialogLocal" width="800">
      <v-card>
        <v-form ref="es" action="#" lazy-validation>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
          <span class="headline">User Profiles</span>
          <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-data-table
                        calculate-widths
                        :headers="headers"
                        :items="items"
                        hide-default-footer
                        :loading="tableloading"
                        loading-text= "Loading Data... Please wait"
                        :items-per-page="100"
                        id="main-table"
                        dense
                    >
            </v-data-table>
          </v-container>
        </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
    </v-row>
</template>

<script>

  export default {
    props: ['dialog', 'modalInfo', 'customText', 'system', 'master'],

    data() {
        return {
            dialogLocal: false,
            items: [],
            headers: [
                { text: '#', value: 'row' },
                { text: 'ID', value: 'ID' },
                { text: 'User ID', value: 'userID' },
                { text: 'Type', value: 'Type' },
                { text: 'Role', value: 'Role'},
            ],
            tableloading: false,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
        }
    },

    watch: {
        dialog: {
        handler: function(value) {
            this.modalHandler();
            if(value == true){
                this.handleValue();
            }
        },
        },

        dialogLocal: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },
    },

    methods: {
        async list(){
            this.items = [];

            this.tableloading = true;
            let response = await this.$store.dispatch('getAdminsTypeRole', {})
            if (response.data.code == 'AP000'){
                let dt = response.data.record;
                let recordCount = response.data.count;
                let arr = [];
                let rowIndex = 0;

                for(var i=0; i<dt.length; i++){
                    if(this.modalInfo.userID == dt[i].userID){
                        rowIndex++;
                        let row = this.page > 1 ? (this.page - 1) * 100 + rowIndex : rowIndex;
                        let id = dt[i].ID;
                        let userid = dt[i].userID;
                        let type = dt[i].Type;
                        let role = dt[i].Role;

                        arr.push({row: row, ID: id, userID: userid, Type: type, Role: role})
                    }
                }

                console.log('arr', arr);

                this.items = arr;
                this.tableloading = false;
            }
        },

        modalHandler(){
            this.dialogLocal = !this.dialogLocal;
        },

        modalCloseHandler(){
            if (this.dialogLocal == false){
                this.$emit('closed', false);
            }
        },

        handleValue(){
            this.list();
            /*
            this.items.parkerID = this.modalInfo.parkerID;
            this.items.name = this.modalInfo.name;
            this.items.mobile = this.modalInfo.mobile;
            this.items.email = this.modalInfo.email;
            */
        },
    }
  }
</script>