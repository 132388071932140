<template>
    <div>
        <v-breadcrumbs :items="crumbs" divider="/" style="background-color: #f0f0f0">
        <template v-slot:item="{ item }">
            <v-breadcrumbs-item
                @click.prevent="goBack()"
                href="#"
                :disabled="item.disabled"
            >
                {{ item.text }}
            </v-breadcrumbs-item>
            </template>
        </v-breadcrumbs>
        <v-card class="mx-auto" outlined tile color="#F8F8FF">
        <v-card-title>Admin SOC</v-card-title>
        <v-container class="fill-height"
        fluid>
            <v-row>
                <v-col cols="12" md="6"><v-btn v-if="validateAccess('us_add')" :loading="btn_loading" :disabled="btn_loading" tile type="submit" small color="primary" @click="viewMore(1, 'create')"><v-icon left x-small dark>fa fa-cogs</v-icon>Create SOC
                    <template v-slot:loader>
                        <span>Loading...</span>
                    </template>
                    </v-btn>
                </v-col>
                <v-col cols="12" md="6">
                      <v-text-field placeholder="Search" class="search-textfield" hide-details outlined dense v-model="search"></v-text-field>
                    </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="12">
                    <span class="text-subtitle-2">User Email: {{$route.query.email}}</span>
                    <v-data-table
                        calculate-widths
                        :headers="headers"
                        :items="items"
                        hide-default-footer
                        :loading="tableloading"
                        :search="search"
                        loading-text= "Loading Data... Please wait"
                        :items-per-page="100"
                        id="main-table"
                        dense
                    >
                    <template v-slot:item.action="{ item }">
                        <v-tooltip top>
                        <template v-slot:activator="{ on }">
                        <span>
                            <a v-on="on" id="custom-hover" @click.prevent="viewMore(item, 'delete')"><v-icon color="red" right x-small>fa fa-trash</v-icon></a>
                        </span>
                        </template>
                        <span>Remove</span>
                        </v-tooltip>
                    </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        </v-card>
        <v-snackbar
        v-model="swal.notification"
        top
        :color="swal.scolor"
        >
            {{ swal.message }}
        </v-snackbar>
        <create :dialog="ma.modal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></create>
        <alert :dialog="ma.modal_delete" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alert>
        <alertbox2 :dialog="ex.modal" @closed="onClosed" :content="ex.content"></alertbox2>
        <viewadmintr :dialog="ma.modal_viewtr" :modalInfo="ma.modalInfo" @closed="onClosed"></viewadmintr>
    </div>
</template>
<script>
import timer from '../../assets/js/sleeper';
import create from '.././modals/CreateSOC';
import alert from '.././modals/Alert';
import {ERR} from '../../assets/js/errhandle.js';
import alertbox2 from '../modals/Notification';
import viewadmintr from '../modals/ViewAdminTR';
//import {rule} from '../../assets/js/checkPerm';

export default {
    name: 'Admin SOC',
    title: 'Admin SOC',
    
    data()  {
        return {
            crumbs: [
                {
                text: 'Home',
                disabled: true,
                href: '#',
                },

                {
                text: 'Users',
                disabled: false,
                href: '#',
                },

                {
                text: 'Admin SOC',
                disabled: true,
                href: '#',
                },
            ],
            items: [],
            search: '',
            headers: [
                { text: '#', value: 'row', width: '3%' },
                { text: 'ID', value: 'ID', width: '3%' },
                { text: 'User ID', value: 'userID', width: '3%' },
                { text: 'System ID', value: 'System', width: '15%' },
                { text: 'Operator ID', value: 'Operator', width: '15%' },
                { text: 'Car Park ID', value: 'Carpark', width: '15%' },
                { text: 'Action', value: 'action', width: '3%' },
            ],
            tableloading: false,
            btn_loading: false,
            pages: 0,
            page: 1,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                confirmCreate: false,

                modal_delete: false,
                modal_update: false,
                readonly: true,
                allInfo: [],

                modal_viewtr: false
            },

            ex: {
                modal: false,
                content: {
                    headline: '',
                    icon: '',
                    chipColor: '',
                    iconColor: '',
                    maintext: '',
                    smalltext: ''
                }
            },

            services: []
        }
    },

    components: {create, alert, alertbox2, viewadmintr},

    async created(){
        this.getUsers();
    },

    methods: {
        async getUsers(){
            this.items = [];

            this.tableloading = true;
            let response = await this.$store.dispatch('getAdminsSOC', {})
            if (response.data.code == 'AP000'){
                let dt = response.data.record;
                let recordCount = response.data.count;
                let arr = [];
                let rowIndex = 0;

                for(var i=0; i<dt.length; i++){
                    if(this.$route.params.id == dt[i].UserID){
                        rowIndex++;
                        let row = this.page > 1 ? (this.page - 1) * 100 + rowIndex : rowIndex;
                        let id = dt[i].ID;
                        let userid = dt[i].UserID;
                        let system = dt[i].SystemID;
                        let operator = dt[i].OperatorID;
                        let carpark = dt[i].CarparkID;

                        arr.push({row: row, ID: id, userID: userid, System: system, Operator: operator, Carpark: carpark});
                    }
                }

                this.items = arr;
                this.tableloading = false;
            }
        },

        validateAccess(val){
            //let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            //return rule.checkaccess(val, access);

            return true;
        },

        async add(v){
            this.btn_loading = true;
            this.tableloading = true;
            try{
                this.swal.scolor = 'green';
                let req = {userid: this.$route.params.id, systemid: v[0].selected, operatorid: v[1].selected, carparkid: v[2].selected};
                let response = await this.$store.dispatch("createSOC", req);
                await timer.sleep(800);
                if (response.data.code === 'AP000'){
                        await this.getUsers();
                        this.swal.notification = true;
                        this.swal.scolor = 'green';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: SOC Created!`;
                }

                else if (response.data.code === 'RD002'){
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: SOC already exists`;
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
                this.btn_loading = false;
            }

            catch(err){
                this.swal.notification = true;
                this.swal.scolor = 'red';
                this.swal.message = ERR.HANDLE(response.data.code);
            }
        },

        async deleteProfile(v){
            try{
                this.tableloading = true;
                console.log(v);
                let req = {userid: this.$route.params.id, systemid: v.System, operatorid: v.Operator, carparkid: v.Carpark};
                let response = await this.$store.dispatch("deleteSOC", req)
                if (response.data.code === 'AP000'){
                        await this.getUsers();
                        this.swal.notification = true;
                        this.swal.scolor = 'green';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: SOC has been deleted!`;
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }
            }

            catch(err){
                this.swal.scolor = 'red';
                this.swal.notification = true;
                this.swal.message = "Something went wrong. Please try again later";
                this.tableloading = false;
            }
        },

        viewMore(row, flag){
            //this.autoRefresh = 0;
            if (flag == 'delete'){
                this.ma.modal_delete = true;
                this.ma.modalInfo = row;
            }

            if (flag == 'create'){
                this.ma.modal = true;
                this.ma.allInfo = this.items;
            }

            if (flag == 'view'){
                this.ma.modal_viewtr = true;
                this.ma.modalInfo = row;
            }

            this.ma.customText = {text1: "Gate ID", text2: "Name"};
            this.ma.modal_action_type = flag;
        },

        onClosed(value){
            console.log(value)
            if (value == false){
                this.ma.modal = value;
                this.ma.modal_delete = value;
                this.ma.modal_update = value;
                this.ex.modal = value;
                this.ma.modal_viewtr = value;
            }
        },

        onConfirm(value){
            console.log(value)
            if (value.flag === 'create'){
                this.ma.confirmCreate = true;
                this.ma.modal = false;
                this.add(value);
            }

            if (value.flag === 'update'){
                this.ma.modal_update = false;
            }

            if (value === true){
                if (this.ma.modal_action_type == 'delete')
                {
                    this.ma.modal_delete = false;
                    this.deleteProfile(this.ma.modalInfo);
                }
            }
        },

        searchTimeOut() {

          clearTimeout(this.timeout);

          // Make a new timeout set to go off in 800ms
          this.timeout = setTimeout(() => {
          this.getUsers();
          }, 800);
        },

        goBack(){
            this.$router.push({name: 'Users'});
        }
    }
}
</script>