<template>
<v-row justify="center">
<v-dialog v-model="dialog_local" max-width="350">
    <v-form ref="ct" action="#" @submit.prevent="beforeCreateForm('create')" lazy-validation>
        <v-card class="mx-auto" tile>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
            Create SOC
            <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
            </v-card-title>
        <v-card-text>
        <v-container class="fill-height"
        fluid >
            <v-row>
                <v-col cols="12" md="12">
                    <v-autocomplete
                        dense
                        v-model="form[0].selected"
                        :items="form[0].system"
                        :rules="form[0].rule"
                        @change="execFilter('system')"
                        item-text="name"
                        item-value="SystemID"
                        label="Select System"
                        outlined
                    ></v-autocomplete>
                    <v-autocomplete
                        dense
                        v-model="form[1].selected"
                        :items="form[1].operator"
                        :rules="form[1].rule"
                        @change="execFilter('operator')"
                        item-text="name"
                        item-value="OperatorID"
                        label="Select Operator"
                        outlined
                    ></v-autocomplete>
                    <v-autocomplete
                        dense
                        v-model="form[2].selected"
                        :items="form[2].carpark"
                        :rules="form[2].rule"
                        item-text="name"
                        item-value="CarParkID"
                        label="Select Car Park"
                        outlined
                    ></v-autocomplete>
                </v-col>
            </v-row>
        </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn tile type="submit" small color="primary"><v-icon left x-small dark>fa fa-save</v-icon>Save</v-btn>
            <v-btn tile color="blue darken-1" text @click="$emit('closed', false)">Close</v-btn>
        </v-card-actions>
        </v-card>
    </v-form>
    <alertbox :dialog="innerModal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
</v-dialog>
</v-row>
</template>
<script>
import alertbox from './Alert';
export default {
    props: ['dialog', 'modalInfo', 'customText', 'system', 'master', 'allInfo'],
    
    data()  {
        return {
            dialog_local: false,
            innerModal: false,
            form: [ 
                {system: [], itemsFull: [], rule: [], selected: '' },
                {operator: [], itemsFull: [], rule: [], selected: '' },
                {carpark: [], itemsFull: [], rule: [], selected: '' }
            ],

            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                edit_modal: false,
                view_modal: false,
                confirmEdit: false,
                confirmCreate: false,
            },
        }
    },

    components: {alertbox},

    watch: {
        dialog: {
        handler: async function(value) {
            this.modalHandler();
            if(value == true){
                this.handleValue();
            }
        },
        },

        dialog_local: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },
    },

    created(){
        this.$watch(() => this.form[0].system, this.onRuleChange);
        this.$watch(() => this.form[1].operator, this.onRuleChange);
        this.$watch(() => this.form[2].carpark, this.onRuleChange);
    },

    methods: {
        async getAllSystem(){
            let list = [];
            try{
                let response = await this.$store.dispatch("getAllSystem2", {search: ''});
                let dt = response.data.record;
                this.form[0].system.push({name: "Select System", SystemID: ""});
                this.form[0].itemsFull.push({name: "Select System", SystemID: ""});
                if (response.data.code == "AP000"){
                    for(var i=0; i<dt.length; i++){
                        dt[i].name = `${dt[i].SystemID} - ${dt[i].SystemName}`;

                        this.form[0].system.push(dt[i]);
                        this.form[0].itemsFull.push(dt[i]);
                    }
                }

                list = dt;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getAllOperator(){
            let list = [];
            try{
                let response = await this.$store.dispatch("getAllOperator", {search: ''});
                let dt = response.data.record;
                this.form[1].operator.push({name: "Select Operator", OperatorID: ""});
                this.form[1].itemsFull.push({name: "Select Operator", OperatorID: ""});
                if (response.data.code == "AP000"){
                    for(var i=0; i<dt.length; i++){
                        dt[i].name = `${dt[i].OperatorID} - ${dt[i].OperatorName}`;

                        this.form[1].operator.push(dt[i]);
                        this.form[1].itemsFull.push(dt[i]);
                    }
                }

                list = dt;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getAllCarpark(){
            let list = [];
            try{
                let response = await this.$store.dispatch("getAllCarpark2", {});
                let dt = response.data.record;
                this.form[2].carpark.push({name: "Select Car Park", CarParkID: ""});
                this.form[2].itemsFull.push({name: "Select Car Park", CarParkID: ""});
                if (response.data.code == "AP000"){
                    for(var i=0; i<dt.length; i++){
                        if (dt[i].CarParkMappingID !== null){
                            //this.carpark.items.push({name: `${dt[i].CarParkMappingID} - ${dt[i].CarParkName}`, CarParkID: dt[i].CarParkMappingID});
                            dt[i].name = `${dt[i].CarParkMappingID} - ${dt[i].CarParkName}`;
                            dt[i].CarParkID = dt[i].CarParkMappingID;
                        }

                        else{
                            //this.carpark.items.push({name: `${dt[i].CarParkID} - ${dt[i].CarParkName}`, CarParkID: dt[i].CarParkID});
                            dt[i].name =  `${dt[i].CarParkID} - ${dt[i].CarParkName}`;
                        }

                        this.form[2].carpark.push(dt[i]);
                        this.form[2].itemsFull.push(dt[i]);
                    }
                }

                list = dt;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        onRuleChange(after, before) {
            this.form[0].rule = [];
            this.form[1].rule = [];
            this.form[2].rule = [];
        },

        async beforeCreateForm(flag){            
            this.form[0].rule = [
                v => !!v || 'Field is required',
            ];

            this.form[1].rule = [
                v => !!v || 'Field is required',
            ];

            this.form[2].rule = [
                v => !!v || 'Field is required',
            ];

            //must set this in order to use 'this' as 'self' inside settimeout etc..
            let self = this;

            setTimeout(function () {
             if (self.$refs.ct.validate()) {
                    self.innerModal = true;
                    self.ma.modalInfo = {id: 1, name: 2};
                    self.ma.modal_action_type = flag;
                }
            });
        },

        modalHandler(){
            this.dialog_local = !this.dialog_local;
        },

        modalCloseHandler(){
            if (this.dialog_local == false){
                this.$emit('closed', false);
            }
        },

        handleValue(){
            this.getAllSystem();
            this.getAllOperator();
            this.getAllCarpark();
            this.form[0].selected = '';
            this.form[1].selected = '';
            this.form[2].selected = '';
        },

        async execFilter(type){
            if (type == 'system'){
                if (true){
                    let self = this;
                    if (self.form[0].selected != ''){
                        let operator = this.form[1].itemsFull.filter(item => {
                        return item.SystemID == self.form[0].selected;
                        });

                        this.form[1].operator = [];
                        this.form[1].selected = '';
                        
                        this.form[1].operator.push({name: "Select Operator", OperatorID: ""});
                        for(var i=0; i<operator.length; i++){
                            operator[i].name = `${operator[i].OperatorID} - ${operator[i].OperatorName}`;
                            this.form[1].operator.push({name: operator[i].name, OperatorID: operator[i].OperatorID});
                        }
                    }

                    else{
                        this.form[1].operator = [];
                        this.form[1].selected = '';
                        this.form[1].operator = this.form[1].itemsFull;
                    }
                }

                if (true){
                    let self = this;
                    if (self.form[1].selected != ''){
                        let carpark = this.form[2].itemsFull.filter(item => {
                            return item.OperatorID == self.form[1].selected;
                        });

                        this.form[2].carpark = [];
                        this.form[2].selected = '';

                        this.form[2].carpark.push({name: "Select Car Park", CarParkID: ""});
                        for(var i=0; i<carpark.length; i++){
                            //carpark[i].name = `${carpark[i].CarParkID} - ${carpark[i].CarParkName}`;
                            //this.carpark.items.push(carpark[i]);

                            if (carpark[i].CarParkMappingID !== null){
                                //this.carpark.items.push({name: `${dt[i].CarParkMappingID} - ${dt[i].CarParkName}`, CarParkID: dt[i].CarParkMappingID});
                                carpark[i].name = `${carpark[i].CarParkMappingID} - ${carpark[i].CarParkName}`;
                                carpark[i].CarParkID = carpark[i].CarParkMappingID;
                            }

                            else{
                                //this.carpark.items.push({name: `${dt[i].CarParkID} - ${dt[i].CarParkName}`, CarParkID: dt[i].CarParkID});
                                carpark[i].name =  `${carpark[i].CarParkID} - ${carpark[i].CarParkName}`;
                            }

                            this.form[2].carpark.push({name: carpark[i].name, CarParkID: carpark[i].CarParkID});
                        }
                    }

                    else{
                        this.form[2].carpark = [];
                        this.form[2].selected = '';
                        this.form[2].carpark = this.form[2].itemsFull;
                    }
                }
            }

            if (type == 'operator'){
                let self = this;
                if (self.form[1].selected != ''){
                    let carpark = this.form[2].itemsFull.filter(item => {
                        return item.OperatorID == self.form[1].selected;
                    });

                    this.form[2].carpark = [];
                    this.form[2].selected = '';

                    this.form[2].carpark.push({name: "Select Car Park", CarParkID: ""});
                    for(var i=0; i<carpark.length; i++){
                        //carpark[i].name = `${carpark[i].CarParkID} - ${carpark[i].CarParkName}`;
                        //this.carpark.items.push(carpark[i]);

                        if (carpark[i].CarParkMappingID !== null){
                            //this.carpark.items.push({name: `${dt[i].CarParkMappingID} - ${dt[i].CarParkName}`, CarParkID: dt[i].CarParkMappingID});
                            carpark[i].name = `${carpark[i].CarParkMappingID} - ${carpark[i].CarParkName}`;
                            carpark[i].CarParkID = carpark[i].CarParkMappingID;
                        }

                        else{
                            //this.carpark.items.push({name: `${dt[i].CarParkID} - ${dt[i].CarParkName}`, CarParkID: dt[i].CarParkID});
                            carpark[i].name =  `${carpark[i].CarParkID} - ${carpark[i].CarParkName}`;
                        }

                        this.form[2].carpark.push({name: carpark[i].name, CarParkID: carpark[i].CarParkID});
                    }
                }

                else{
                    this.form[2].carpark = [];
                    this.form[2].selected = '';
                    this.form[2].carpark = this.form[2].itemsFull;
                }
            }
        },

        //set the action to be sent to watcher to execute the create/edit function
        onConfirm(value){
          //console.log(value);
                if (this.ma.modal_action_type == 'create'){
                    this.form.flag = 'create';
                    this.$emit('confirm', this.form);
                }

                this.innerModal = false;
        },

        onClosed(value){
            if (value == false){
                this.innerModal = value;
            }
        },
    }
}
</script>